let MAAT_ROOT_PATH = '';
const FEEDBACK_ROOT_PATH = '/feedback/';
let rootPrefix = 'xuanjing';
if (typeof ROOT_PREFIX !== 'undefined') {
  rootPrefix = ROOT_PREFIX;
  MAAT_ROOT_PATH = `/${rootPrefix}/maat/`;
} else {
  // 地址由URL决定
  const ur = window.location.pathname.match(/^\/(xuanjing[^/]*?)\//);
  MAAT_ROOT_PATH = (ur && ur.length > 0) ? url[0] + '/maat/': '/maat/';
}

export {
  MAAT_ROOT_PATH,
  FEEDBACK_ROOT_PATH,
};

// case1: 预发布 maat_preview
// case2: 快照页 https://maat.woa.com/maat/ShowReviewSnapshot/?uid=25575131&aid=7297872171&pageType=ad#/
// case3: 页面跟路径和后端跟路径不同
