
export const CREATIVE_TAKE_TASK_TIME_RECORD = {
    SINGLE_CREATIVE_STAY_TIME: 'single_creative_audit_stay_time',
    SINGLE_CREATIVE_MARK_STAY_TIME: 'single_creative_mark_stay_time',
    CREATIVE_CERTIFICATION_SPEND_TIME: 'single_creative_certification_spend_time',
    CREATIVE_DEST_URL_STAY_TIME: 'single_creative_dest_url_stay_time',
    CREATIVE_COMPLETE_IMAGE_STAY_TIME: 'single_creative_image_stay_time',
    CREATIVE_REJECT_DIALOG_STAY_TIME: 'single_creative_reject_dialog_stay_time',
    CREATIVE_COMPLETE_VIDEO_STAY_TIME: 'single_creative_video_stay_time',
    CREATIVE_MEDIA_COMPLETE_DIALOG_STAY_TIME: 'single_creative_complete_media_dialog_stay_time',
    CREATIVE_REJECT_WITH_MARKED_RECORD: 'creative_reject_with_marked_record',
    MINI_PROGRAM_REVIEW: 'creative_mini_program_review',
    MINI_PROGRAM_PC_REVIEW_FAIL_FEEDBACK: 'creative_mini_program_pc_preview_fail_feedback',
    MINI_PROGRAM_QR_CLICK_AFTER_PC_PREVIEW: 'creative_mini_program_qr_click_after_pc_preview',
  };
  
  export const VIDEO_WAITING_RECORD = 'frontend_video_waiting_time';
  export const VIDEO_PLAY = 'frontend_video_play';
  export const TABLE_RENDER = 'frontend_table_render';
  export const MEDIA_TIME = 'frontend_media_time';
  export const TCC_VIDEO_REVIEW = 'base_element_tcc_video_dialog_stay_time';
  export const SYSTEM_STAY_TIME = 'system_stay_time';
  export const ADVERTISER_REMARK_STAY_TIME = 'advertiser_remark_stay_time';
  export const ID_COPY_COUNT = 'id_copy_count';
  export const SHOW_MORE_COUNT = 'show_more_count';
  export const DC_GROUP_COUNT = 'dc_group_count';
  
  export const REASON_SELECT_WITH_KEYWORD = 'reject_reason_keyword_select';
  export const REASON_CUSTOM_EDIT = 'reject_reason_custom_edit';
  export const CREATIVE_TAKE_REPEAT_TASK = 'creative_again_stack_task';
  export const MINI_PROGRAM_PC_PREVIEW_CLICK = 'mini_program_pc_preview_click';
  export const FRONT_COMMON = 'frontend_common';
  