import ErrorMsgUtil from '../utils/ErrorMsgUtil';

const SomeCacheInfo = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ajaxExceptionReport: ({ url, error, response, subType }) => {
    window.aegis && window.aegis.report({
      type: 'ajaxResponseException',
      url,
      error,
      response,
      subType,
    });
  },
  takeTaskPage: {
    auditResponseExceptionReport: (response) => {
      const defaultType = 'takeTaskPageCreativeAuditException : ';
      let type = defaultType;
      let recordResponseInfo;
      if (!response) {
        type += 'response is null';
      } else {
        const { code, data } = response;
        if (code !== 0) {
          type += 'response code is not 0';
          recordResponseInfo = response;
        } else if (!data) {
          type += 'response .data is null or undefined';
          recordResponseInfo = response;
        } else if (!ErrorMsgUtil.creativeReivew.parseErrorCode(data.reason)) {
          type += 'reason is unknown';
          recordResponseInfo = {
            data: {
              audited: data.audited,
              reason: data.reason,
            },
          };
        }
      }
      if (type !== defaultType) {
        window.aegis && window.aegis.report({
          type,
          responseKeyInfo: recordResponseInfo,
        });
      }
    },
    takeTaskReqInfo: (param) => {
      const type = 'takeTaskReq';
      window.aegis && window.aegis.infoAll({
        type,
        param,
      });
    },
    takeTaskReqComplete: (response) => {
      const defaultType = 'takeTaskResponse';
      let type = defaultType;
      let responseInfo;
      if (!response) {
        type += ' no response';
      } else {
        const { code, data } = response;
        if (data) {
          const { tasks } = data;
          const creativeIds = [];
          if (tasks && tasks.length) {
            tasks.forEach(({ baseInfo }) => {
              creativeIds.push({
                tid: baseInfo.tId,
                uid: baseInfo.uId,
                aid: baseInfo.aId,
              });
            });
            SomeCacheInfo.takeTaskIds = creativeIds;
            responseInfo = {
              tasks: creativeIds,
              code,
              reviewChannel: data.reviewChannel,
            };
          } else if (code !== 0) {
            type += ' code: ';
            type += code;
          }
        } else {
          type += 'no data';
        }
      }
      if (type !== defaultType) {
        window.aegis && window.aegis.report({
          type,
          responseInfo,
        });
      } else {
        window.aegis && window.aegis.infoAll({
          type,
          responseInfo,
        });
      }
    },
    clearTakeTaskIds: () => {
      SomeCacheInfo.takeTaskIds = undefined;
      SomeCacheInfo.takeTaskRenderedIds = undefined;
    },
    renderedIdsRecord: (creativeIdInfo) => {
      SomeCacheInfo.takeTaskRenderedIds = SomeCacheInfo.takeTaskRenderedIds || [];
      SomeCacheInfo.takeTaskRenderedIds.push(creativeIdInfo);
    },
    renderedComplete() {
      let hasException = false;
      const renderedIds = SomeCacheInfo.takeTaskRenderedIds || [];
      SomeCacheInfo.takeTaskIds && SomeCacheInfo.takeTaskIds.forEach((creativeIdInfo) => {
        const findCallback = t => t.tid === creativeIdInfo.tid
        && t.uid === creativeIdInfo.uid && t.aid === creativeIdInfo.aid;
        const index = renderedIds.findIndex(findCallback);
        if (index === -1) {
          hasException = true;
        }
      });
      if (hasException) {
        window.aegis && window.aegis.report({
          type: 'takeTaskRenderException',
          takeTaskIds: SomeCacheInfo.takeTaskIds,
          takeTaskRenderedIds: renderedIds,
        });
      }

      window.aegis && window.aegis.infoAll({
        type: 'takeTaskRenderComplete',
        takeTaskRenderedIds: renderedIds,
      });

      this.clearTakeTaskIds();
    },
  },
};
