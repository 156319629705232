
export const WXLOGIN_APPID_LIST = {
  "t.xuanjing.qq.com": "wx7b0cd712ea080aee",
  "localhost:3000": "wx7b0cd712ea080aee",
  "xuanjing.qq.com": "wx8de6387486fea291",
};
export const TOKEN_KEY = "onion-login-token";

export const ERR_CODE = {
  // ERRCODE_USER_FREQUENT_LIMIT : -8003, // 频率限制
  // ERRCODE_REITERATE_QUERY : -5000, // 轮询该请求
  ERRCODE_USER_NO_RIGHT: 1005, // 权限不足
	ERRCODE_NEED_LOGIN: 1006, // 用户未登录
}
