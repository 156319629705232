import { LOGIN_HREF, LOGIN_HREF_QQ } from "../consts/Urls";
import Urls from "../consts/Urls";
import HttpUtils from "./HttpUtils";

// function redirectToLogin() {
//   const currentHref = location.href;
//   if (window.location.host === "review.e.qq.com") {
//     const loginHref = `${LOGIN_HREF_QQ}?maat_url=`;
//     window.location.href = loginHref + encodeURIComponent(currentHref);
//   } else {
//     const loginHref = `${LOGIN_HREF}?maat_url=`;
//     window.location.href = loginHref + encodeURIComponent(currentHref);
//   }
// }

export function isWxLogin(callback) {
  handleLogin()
    .then((res) => {
      if (res && res.code === 0) {
        getUserInfo()
          .then((res) => {
            if (res && res.code === 0) {
              window.pageProps.nickName = res.nickname;
              window.pageProps.reviewerName = res.openid;
              window.pageProps.avatar = res.head_img_url;
            }
            callback && callback();
          })
          .catch(function (error) {
            console.log(error);
            // window.alert(error.msg || error.message);
            callback && callback();
          });
      } else {
        callback && callback();
      }
    })
    .catch(function (error) {
      console.log(error);
      // window.alert(error.msg || error.message);
      callback && callback();
    });
}

/**
 * @description 绑定事件 on(element, event, handler)
 */
export const on = (function () {
  if (document.addEventListener) {
    return function (element, event, handler) {
      if (element && event && handler) {
        element.addEventListener(event, handler, false);
      }
    };
  } else {
    return function (element, event, handler) {
      if (element && event && handler) {
        element.attachEvent("on" + event, handler);
      }
    };
  }
})();

/**
 * @description 解绑事件 off(element, event, handler)
 */
export const off = (function () {
  if (document.removeEventListener) {
    return function (element, event, handler) {
      if (element && event) {
        element.removeEventListener(event, handler, false);
      }
    };
  } else {
    return function (element, event, handler) {
      if (element && event) {
        element.detachEvent("on" + event, handler);
      }
    };
  }
})();

export const handleLogin = async (params) => {
  const response = await HttpUtils.getRequest(Urls.WXLOGIN, params);
  return response;
};

export const getUserInfo = async (params) => {
  const response = await HttpUtils.getRequest(Urls.USERINFO, params);
  return response;
};

const getYearMonthDay = (date) => {
  let _year = date.getFullYear();
  let _month = date.getMonth() + 1;
  let _day = date.getDate();
  let _seperator = '-';
  return _year + _seperator + _month + _seperator + _day;
};

const addWatermark = (content) => {
  const WIDTH = 160;
  const HEIGHT = 160;
  const canvas = document.createElement("canvas");
  canvas.width = WIDTH;
  canvas.height = HEIGHT;
  const ctx = canvas.getContext("2d");
  if (!ctx) return;

  ctx.font = "17px sans-serif";
  ctx.fillStyle = "#9f9f9f";
  ctx.globalAlpha = 0.1;
  ctx.translate(WIDTH / 2, HEIGHT / 2);
  ctx.rotate(-Math.PI / 6);
  ctx.textAlign = "center";
  ctx.fillText(`${content}`, 0, 0);
  const dataURL = canvas.toDataURL("image/png");

  const mask = document.createElement("div");
  mask.style.width = "100vw";
  mask.style.height = "100vh";
  mask.style.position = "fixed";
  mask.style.left = "0";
  mask.style.top = "0";
  mask.style.zIndex = "10000";
  mask.style.pointerEvents = "none";
  mask.style.backgroundImage = `url(${dataURL})`;

  document.body.append(mask);
};

export const addWatermarkWithDate = (content) => {
  const text = content + '\n' + getYearMonthDay(new Date());
  return addWatermark(text);
}
