import { MAAT_ROOT_PATH, FEEDBACK_ROOT_PATH } from './ApiRootPath';

const PATH = {
  // 通用
  WXLOGIN: `${MAAT_ROOT_PATH}api/v1/wxauth`, //api/v1/proxy/lkReviewWechatLogin`,
  USERINFO: `${MAAT_ROOT_PATH}api/v1/proxy/lkReviewGetWxUserInfo`,
  LOGOUT: `${MAAT_ROOT_PATH}api/v1/auth/logout`,
  CLICK_MO_REPORT: `${MAAT_ROOT_PATH}mo/report`,
  GET_ALL_CHANNELS: `${MAAT_ROOT_PATH}api/v1/review/getAllReviewChannelItem`,
  GET_IMAGE_URL: `${MAAT_ROOT_PATH}api/v1/review/cfsfile`,
  GET_INDUSTRY_INFO: `${MAAT_ROOT_PATH}api/v1/review/getAllIndustryInfo`,

  // 系统挂起
  SUSPEND_SYSTEM_SUBMIT: `${MAAT_ROOT_PATH}api/v1/suspendSystem/submit`,
  SUSPEND_SYSTEM_OUT: `${MAAT_ROOT_PATH}api/v1/suspendSystem/out`,
  SUSPEND_SYSTEM_QUERY: `${MAAT_ROOT_PATH}api/v1/suspendSystem/query`,
  SUSPEND_SYSTEM_REASON_QUERY: `${MAAT_ROOT_PATH}api/v1/suspendSystem/reasonQuery`,

  // 签到系统
  SIGN_INFO: `${MAAT_ROOT_PATH}api/v1/reviewprivilege/signInfo`,
  SIGN_IN: `${MAAT_ROOT_PATH}api/v1/reviewprivilege/signIn`,
  SIGN_OUT: `${MAAT_ROOT_PATH}api/v1/reviewprivilege/signOut`,

  // 素材审核领单页面
  CREATIVE_AUDIT_TAKE_TASKS: `${MAAT_ROOT_PATH}api/v1/creativeReview/takeTasks`,
  CREATIVE_AUDIT_REFRESH_TASKS: `${MAAT_ROOT_PATH}api/v1/creativeReview/refreshTasks`,
  CREATIVE_AUDIT_TASK_STATICS: `${MAAT_ROOT_PATH}api/v1/creativeReview/getTaskStaticInfo`,
  FETCH_CHANNEL_WARN_INFO: `${MAAT_ROOT_PATH}api/v1/creativeReview/getWarningSwimlanes`,
  FETCH_CHANNEL_SIT_SETS: `${MAAT_ROOT_PATH}api/v1/creativeReview/getSitesetOfTopChannels`,
  TAKE_TASK_LOG_OUT: `${MAAT_ROOT_PATH}api/v1/reviewprivilege/reviewLogout`,
  GET_CREATIVE_LOGIN_STATUS: `${MAAT_ROOT_PATH}api/v1/reviewprivilege/judgeOnline`,
  CREATIVE_AUDIT_HANG_ON: `${MAAT_ROOT_PATH}api/v1/creativeReview/delayTask`,
  CREATIVE_AUDIT_TIMING_START: `${MAAT_ROOT_PATH}api/v1/creativeReview/timeTask`,
  CREATIVE_AUDIT_SET_TIME_OUT: `${MAAT_ROOT_PATH}api/v1/creativeReview/taskTimeout`,
  CREATIVE_AUDIT_GET_TIME_OUT_COUNT: `${MAAT_ROOT_PATH}api/v1/creativeReview/getTimeoutCount`,
  GET_SG_CREATIVE_PREVIEW_INFO: `${MAAT_ROOT_PATH}api/v1/creativeReview/getPreviewDisplayData`,
  GET_CHANNELS: `${MAAT_ROOT_PATH}api/v1/creativeReview/getChannels`,

  GET_ADGROUP_RISKMARK_DETAIL: `${MAAT_ROOT_PATH}api/v1/review/getAdGroupRiskMarkDetail`,
  GET_ADVERTISER_RISKMARK_DETAIL: `${MAAT_ROOT_PATH}api/v1/review/getAdvertiserRiskMarkDetail`,

  INSERT_ADGROUP_RISKMARK_LOG: `${MAAT_ROOT_PATH}api/v1/review/insertAdGroupRiskMarkLog`,
  INSERT_ADVERTISER_RISKMARK_LOG: `${MAAT_ROOT_PATH}api/v1/review/insertAdvertiserRiskMarkLog`,

  CREATIVE_REVIEW: `${MAAT_ROOT_PATH}api/v1/creativeReview/doCreativeReview`,
  CHANNEL_REVIEW: `${MAAT_ROOT_PATH}api/v1/creativeReview/doChannelReview`,
  AD_GROUP_REVIEW: `${MAAT_ROOT_PATH}api/v1/creativeReview/doAdGroupReview`,

  // 素材审核之落地页独立审核页面
  CREATIVE_LANDING_PAGE_TIP_INCONSISTENT: `${MAAT_ROOT_PATH}api/v1/creativeReview/markLandingPageInconsistent`,

  // 获取广告位信息
  CREATE_QUERY_PLACEMENT_GROUP: `${MAAT_ROOT_PATH}api/v1/creativeReview/queryPlacementGroup`,
  // 素材审核查询台页面
  CREATIVE_SEARCH: `${MAAT_ROOT_PATH}api/v1/creativeReview/search`,
  CREATIVE_SEARCH_ASYNC: `${MAAT_ROOT_PATH}api/v1/creativeReview/searchAsync`,
  CREATIVE_SEARCH_ASYNC_TASK_RETRY: `${MAAT_ROOT_PATH}api/v1/creativeReview/retrySearchAsync`,
  CREATIVE_LITE_SEARCH: `${MAAT_ROOT_PATH}api/v1/creativeReview/searchLite`,
  CREATIVE_DOWNLOAD: `${MAAT_ROOT_PATH}api/v1/creativeReview/download`,
  GET_SEARCH_INIT_DATA: `${MAAT_ROOT_PATH}api/v1/search/getSearchInitData`,
  FILE_UPLOAD: `${MAAT_ROOT_PATH}api/v1/search/fileUpload`,
  FETCH_CRETIVE_CONSOLE_PURPOSE: `${MAAT_ROOT_PATH}api/v1/creativeReview/getQueryConsoleOperationSources`,
  CREATIVE_QUERY_REPORT: `${MAAT_ROOT_PATH}api/v1/creativeReview/queryReport`,
  LITE_SEARCH_REJECT_CREATIVE_CHANNEL: `${MAAT_ROOT_PATH}api/v1/creativeReview/doCreativeReviewLite`,
  GET_CREATIVE_KEYWORD: `${MAAT_ROOT_PATH}api/v1/creativeKeyword/query`,

  // 获取审核历史
  GET_CREATIVE_REVIEWHISTORY_DETAIL: `${MAAT_ROOT_PATH}api/v1/review/creativeReview/getLogs`,

  // 广告主备注信息
  GET_ADUSERREMARKINFO: `${MAAT_ROOT_PATH}api/v1/review/getAdUserRemarkInfo`,
  BATCH_ADD_AD_USER_REMARK: `${MAAT_ROOT_PATH}api/v1/review/batchAddAdUserRemarkInfo`,
  ADD_ADUSERREMARKINFO: `${MAAT_ROOT_PATH}api/v1/review/addAdUserRemarkInfo`,
  DELETE_ADUSERREMARKINFO: `${MAAT_ROOT_PATH}api/v1/review/deleteAdUserRemarkInfo`,

  GET_REVIEWSNAPSHOT: `${MAAT_ROOT_PATH}api/v1/review/getReviewSnapshot`,
  GET_CITY_AND_PROVINCE_INFO: `${MAAT_ROOT_PATH}api/v1/review/getCityAndProvinceInfo`,

  GET_IMAGERECRESULT: `${MAAT_ROOT_PATH}api/v1/creativeReview/getImageRecResult`,
  GET_CREATIVE_IMAGE_ORC_DETAIL: `${MAAT_ROOT_PATH}api/v1/creativeReview/getCreativeImageOcrDetail`,
  GET_CREATIVE_IMAGE_DETAIL_INFO: `${MAAT_ROOT_PATH}api/v1/creativeReview/getCreativeImageDetailInfo`,
  GET_TEXT_REMARK_RESULT: `${MAAT_ROOT_PATH}api/v1/creativeReview/getTextRemarkResult`,
  GET_SIMILAR_IMAGE_BY_URL: `${MAAT_ROOT_PATH}api/v1/creativeReview/getSimilarImageByURL`,
  GET_IMAGE_DETAIL_INFO: `${MAAT_ROOT_PATH}api/v1/creativeReview/getImageDetailInfo`,
  ADD_REVIEW_URL_OPEN_REQ: `${MAAT_ROOT_PATH}api/v1/review/addReviewUrlOpenRequest`,

  GET_SNAPSHOT: `${MAAT_ROOT_PATH}ShowReviewSnapshot${MAAT_ROOT_PATH ? '/' : ''}`,
  GET_ALL_REVIEW_USERS: `${MAAT_ROOT_PATH}api/v1/creativeReview/getAllReviewUsers`,
  GET_REVIEW_PROGRESS_BY_NAME: `${MAAT_ROOT_PATH}api/v1/creativeReview/getReviewProgress`,
  GET_REVIEW_PROGRESS_FILE: `${MAAT_ROOT_PATH}api/v1/creativeReview/getReviewProgressFile`,
  GET_REVIEW_STATS: `${MAAT_ROOT_PATH}api/v1/creativeReview/getGroupedStatistics`,
  GET_REVIEW_STATS_FILE: `${MAAT_ROOT_PATH}api/v1/creativeReview/getGroupedStatisticsFile`,
  AD_WX_VIDEO_LIST: `${MAAT_ROOT_PATH}api/v1/creativeReview/addWxVideoListV2`,

  // 系统工具页面
  SYSTEM_TOOL_ADD_CREATIVE_TASK: `${MAAT_ROOT_PATH}api/v1/systemTool/addCreativeTasks`,
  SYSTEM_TOOL_CREATIVE_TAKE_TASKS: `${MAAT_ROOT_PATH}api/v1/systemTool/takeTasks`,
  SYSTEM_TOOL_GET_CHANNEL_STATIC_INFO: `${MAAT_ROOT_PATH}api/v1/systemTool/getChannelStaticInfo`,
  SYSTEM_TOOL_GET_CHANNEL_REVIEW_TASK_INFO: `${MAAT_ROOT_PATH}api/v1/systemTool/getChannelReviewTaskInfo`,
  SYSTEM_TOOL_GET_ONLINE_REVIEWERS: `${MAAT_ROOT_PATH}api/v1/systemTool/getCurrentReviewers`,
  SYSTEM_TOOL_GET_ONE_REVIEWER_TASK_INFO: `${MAAT_ROOT_PATH}api/v1/systemTool/getReviewerTaskInfo`,
  SYSTEM_TOOL_GET_ALL_REVIEWER_TIMEOUT_INFO: `${MAAT_ROOT_PATH}api/v1/systemTool/getAllReviewerTimeoutInfoList`,
  SYSTEM_TOOL_WARNING_CREATIVE_TASKS: `${MAAT_ROOT_PATH}api/v1/systemTool/warningAdvertiserReviewTasks`,
  SYSTEM_TOOL_AD_MASTER_CREATIVE_TASKS: `${MAAT_ROOT_PATH}api/v1/systemTool/adMasterReviewTasks`,
  SYSTEM_TOOL_KEY_ACCOUNT_CREATIVE_TASKS: `${MAAT_ROOT_PATH}api/v1/systemTool/kaReviewTasks`,

  SYSTEM_TOOL_ADVERTISER_TAKE_TASKS: `${MAAT_ROOT_PATH}api/v1/systemTool/getAllTaskInfo`,

  SYSTEM_TOOL_AD_REVIEW_PROCESS_INFO: `${MAAT_ROOT_PATH}api/v1/systemTool/queryAdReviewProcess`,


  SYSTEM_TOOL_ADVERTISER_RETURN_TASK: `${MAAT_ROOT_PATH}api/v1/systemTool/returnUserTask`,
  SYSTEM_TOOL_RETURN_CREATIVE_TASK: `${MAAT_ROOT_PATH}api/v1/systemTool/returnAllTask`,
  SYSTEM_TOOL_DELETE_CREATIVE_TASK: `${MAAT_ROOT_PATH}api/v1/systemTool/deleteCreativeTask`,
  SYSTEM_TOOL_ADVERTISER_DELETE_TASK: `${MAAT_ROOT_PATH}api/v1/systemTool/deleteUserTask`,
  SYSTEM_TOOL_QUERY_REDIS_VALUE: `${MAAT_ROOT_PATH}api/v1/systemTool/queryRedisValue`,
  SYSTEM_TOOL_TANWAN_MONITOR_VALUE: `${MAAT_ROOT_PATH}api/v1/admin/startTanwanMonitor`,
  SYSTEM_TOOL_UPDATE_REDIS_VALUE: `${MAAT_ROOT_PATH}api/v1/systemTool/updateRedisValue`,
  SYSTEM_TOOL_QUERY_VERIFY_INVOICE: `${MAAT_ROOT_PATH}api/v1/systemTool/queryVerifyInvoiceSummary`,
  SYSTEM_TOOL_RESOURCE_ALLOCATION: `${MAAT_ROOT_PATH}api/v1/reviewprivilege/queryChannelStatistics`,
  SYSTEM_TOOL_ALL_PRIVILEGE: `${MAAT_ROOT_PATH}api/v1/rejectreason/getPrivileges`,
  SYSTEM_TOOL_CHECK_CHANNEL_BY_REVIEW_TIME: `${MAAT_ROOT_PATH}api/v1/systemTool/checkChannelStatusByReviewLog`,
  SYSTEM_TOOL_CHECK_CHANNEL_BY_UIDAID: `${MAAT_ROOT_PATH}api/v1/systemTool/checkAidReviewStatus`,
  SYSTEM_TOOL_QUERY_ALL_PRIVILEGE: `${MAAT_ROOT_PATH}api/v1/systemTool/queryAllReviewPrivilege`,
  SYSTEM_TOOL_UPDATE_PRIVILEGE_DESCRIPTION: `${MAAT_ROOT_PATH}api/v1/systemTool/updatePrivilegeDescription`,
  SYSTEM_TOOL_UPDATE_PRIVILEGE_APPLY_TYPE: `${MAAT_ROOT_PATH}api/v1/systemTool/updatePrivilegeApplyType`,
  SYSTEM_TOOL_UPDATE_PRIVILEGE_NAME: `${MAAT_ROOT_PATH}api/v1/systemTool/updatePrivilegeName`,
  SYSTEM_TOOL_INSERT_PRIVILEGE: `${MAAT_ROOT_PATH}api/v1/systemTool/insertPrivilege`,
  SYSTEM_TOOL_RESET_REVIEW_POLICY: `${MAAT_ROOT_PATH}api/v1/admin/resetDnsReviewPolicyTablesByProdTables`,
  SYSTEM_TOOL_QUERY_INDUSTRY_BY_SHORT_ID: `${MAAT_ROOT_PATH}api/v1/admin/queryIndustryInfo`,
  SYSTEM_TOOL_RESET_REVIEW_POLICY_SIMULATION: `${MAAT_ROOT_PATH}api/v1/admin/resetSimulationReviewPolicyTablesByProdTables`,


  // 拒绝原因
  GET_REJECT_REASON_POP_INFO: `${MAAT_ROOT_PATH}api/v1/rejectreason/getRejectReasonPopInfo`,
  GET_VIDEO_REJECT_REASON_POP_INFO: `${MAAT_ROOT_PATH}api/v1/rewardVideo/getLatestReasons`,
  GET_REJECT_REASON_LIST: `${MAAT_ROOT_PATH}api/v1/rejectreason/getRejectReasonList`,
  GET_ALL_GATEGORY_TAG_LIST: `${MAAT_ROOT_PATH}api/v1/rejectreason/getAllRejectInfoCategoryTags`,
  GET_ALL_REJECT_INFO_LABEL_LIST: `${MAAT_ROOT_PATH}api/v1/rejectreason/getAllRejectInfoLabels`,
  QUERY_REJECT_REASON: `${MAAT_ROOT_PATH}api/v1/rejectreason/search`,
  UPDATE_REJECT_REASON: `${MAAT_ROOT_PATH}api/v1/rejectreason/update`,
  CREATE_REJECT_REASON: `${MAAT_ROOT_PATH}api/v1/rejectreason/create`,
  BATCH_UPDATE_REJECT_REASON: `${MAAT_ROOT_PATH}api/v1/rejectreason/batchUpdate`,
  DELETE_REJECT_REASON: `${MAAT_ROOT_PATH}api/v1/rejectreason/delete`,
  DOWNLOAD_ALL_REJECT_REASON: `${MAAT_ROOT_PATH}api/v1/rejectreason/downloadAllRejectReason`,
  GET_REJECT_REASON_TAGS: `${MAAT_ROOT_PATH}api/v1/rejectreason/tagged/getAllRejectInfoCategoryNewTags`,
  GET_REJECT_REASON_TAGS_BY_TYPE: `${MAAT_ROOT_PATH}api/v1/rejectreason/tagged/getRejectInfoCategoryNewTags`,
  REJECT_REASON_UPDATE_TAG: `${MAAT_ROOT_PATH}api/v1/rejectreason/tagged/updateRejectInfoCategoryNewTag`,
  REJECT_REASON_CREATE_TAG: `${MAAT_ROOT_PATH}api/v1/rejectreason/tagged/createRejectInfoCategoryNewTag`,
  REJECT_REASON_DELETE_TAG: `${MAAT_ROOT_PATH}api/v1/rejectreason/tagged/deleteRejectInfoCategoryNewTag`,
  REJECT_REASON_FETCH_WHITE_LIST: `${MAAT_ROOT_PATH}api/v1/rejectreason/tagged/getReviewPolicyPardonInfo`,
  REJECT_REASON_DELETE_WHITE_SCENE: `${MAAT_ROOT_PATH}api/v1/rejectreason/tagged/deleteReviewPolicyPardonInfo`,
  REJECT_REASON_CREATE_WHITE_SCENE: `${MAAT_ROOT_PATH}api/v1/rejectreason/tagged/createReviewPolicyPardonInfo`,
  REJECT_REASON_UPDATE_WHITE_SCENE: `${MAAT_ROOT_PATH}api/v1/rejectreason/tagged/updateReviewPolicyPardonInfo`,
  DELETE_REJECT_INFO_LABEL: `${MAAT_ROOT_PATH}api/v1/rejectreason/deleteRejectInfoLabel`,
  CREATE_REJECT_INFO_LABEL: `${MAAT_ROOT_PATH}api/v1/rejectreason/createRejectInfoLabel`,

  // 角色权限
  BATCH_UPDATE_REVIEW_PRIVILEGE_USER: `${MAAT_ROOT_PATH}api/v1/reviewprivilege/batchInsertUserPrivilege`,
  QUERY_REVIEW_PRIVILEGE_USER_LIST: `${MAAT_ROOT_PATH}api/v1/reviewprivilege/queryReviewUserList`,
  QUERY_REVIEW_PRIVILEGE_TEAM_GROUP: `${MAAT_ROOT_PATH}api/v1/reviewprivilege/queryTeamGroup`,
  CREATE_REVIEW_PRIVILEGE_USER: `${MAAT_ROOT_PATH}api/v1/reviewprivilege/createReviewUser`,
  UPDATE_REVIEW_PRIVILEGE_USER: `${MAAT_ROOT_PATH}api/v1/reviewprivilege/updateReviewUser`,
  DELETE_REVIEW_PRIVILEGE_USER: `${MAAT_ROOT_PATH}api/v1/reviewprivilege/deleteReviewUser`,
  QUERY_REVIEW_PRIVILEGE_INTERNAL: `${MAAT_ROOT_PATH}api/v1/reviewprivilege/queryReviewPrivilege`,
  UPDATE_REVIEW_PRIVILEGE_INTERNAL: `${MAAT_ROOT_PATH}api/v1/reviewprivilege/updateReviewPrivilege`,
  QUERY_REVIEW_USER_PRIVILEGE_INTERNAL: `${MAAT_ROOT_PATH}api/v1/reviewprivilege/queryReviewUserPrivilege`,
  RESET_REVIEW_USER_SECOND_PASSWORD: `${MAAT_ROOT_PATH}api/v1/reviewprivilege/resetSecondPassword`,

  // 开户快照与封停库对比
  GET_ADVERTISER_FREEZED_LIST: `${MAAT_ROOT_PATH}api/v1/advertiserLandingPagePatrolInfo`,
  SET_RISK_FOR_ADVERTISER_FREEZE_CONTRACT: `${MAAT_ROOT_PATH}api/v1/advertiserLandingPagePatrolInfo`,
  GET_FREEZE_SNAPSHOT: `${MAAT_ROOT_PATH}api/v1/freezeSnapshot`,
  GET_USER_AUTH: `${MAAT_ROOT_PATH}api/v1/responsibility`,
  GET_ALL_CHECK_ERROR_TYPES: `${MAAT_ROOT_PATH}api/v1/rejectreason/getAllCheckErrorTypes`,


  // 广告主审核
  ADVERTISER_REVIEW_TAKE_TASK: `${MAAT_ROOT_PATH}api/v1/advertiserReview/takeTasks`,
  ADVERTISER_REVIEW_SEARCH: `${MAAT_ROOT_PATH}api/v1/advertiserReview/search`,
  ADVERTISER_REVIEW: `${MAAT_ROOT_PATH}api/v1/advertiserReview/doReview`,
  ADVERTISER_VERIFY: `${MAAT_ROOT_PATH}api/v1/advertiserReview/verify`,
  GET_ADVERTISER_REVIEW_LOGS: `${MAAT_ROOT_PATH}api/v1/advertiserReview/getReviewLogs`,
  ADVERTISER_REJECT_RELATE: `${MAAT_ROOT_PATH}api/v1/advertiserReview/doBatchRelateDeny`,
  ADVERTISER_FETCH_RELATE_SUB_UID: `${MAAT_ROOT_PATH}api/v1/advertiserReview/queryRelateAccountInfos`,

  // 广告主查询
  ADVERTISER_SEARCH: `${MAAT_ROOT_PATH}api/v1/advertiserReview/search`,
  ADVERTISER_BATCH_REVIEW: `${MAAT_ROOT_PATH}api/v1/advertiserReview/batchReviewV2`,

  // 落地页审核
  LANDING_PAGE_TAKE_TASK: `${MAAT_ROOT_PATH}api/v1/landingPage/takeTask`,
  LANDING_PAGE_GET_INIT_DATA: `${MAAT_ROOT_PATH}api/v1/landingPage/getInitData`,
  LANDING_PAGE_REVIEW: `${MAAT_ROOT_PATH}api/v1/landingPage/review`,
  LANDING_PAGE_GET_HISTORY: `${MAAT_ROOT_PATH}api/v1/landingPage/getReviewLogs`,
  LANDING_PAGE_GET_RELATION_ADS: `${MAAT_ROOT_PATH}api/v1/landingPage/getRelationAds`,
  LANDING_PAGE_SEARCH: `${MAAT_ROOT_PATH}api/v1/landingPage/search`,
  LANDING_PAGE_GET_PREVIEW_URL: `${MAAT_ROOT_PATH}api/v1/landingPage/getPreviewUrl`,
  LANDING_PAGE_GET_REVIEWER_TASKS: `${MAAT_ROOT_PATH}api/v1/landingPage/getReviewerTasks`,
  LANDING_PAGE_GET_ALL_TASKS: `${MAAT_ROOT_PATH}api/v1/landingPage/getAllTask`,
  LANDING_PAGE_RETURN_TASKS: `${MAAT_ROOT_PATH}api/v1/landingPage/returnTask`,
  LANDING_PAGE_DELETE_TASK: `${MAAT_ROOT_PATH}api/v1/landingPage/deleteTask`,
  LANDING_PAGE_GET_LATEST_REASONS: `${MAAT_ROOT_PATH}api/v1/landingPage/getLatestReasons`,


  // 质检工具
  CHECK_GET_SEARCH_INIT_DATA: `${MAAT_ROOT_PATH}api/v1/qualificationCheck/getSearchInitData`,
  CHECK_GET_REJECT_REASON_FEATURE: `${MAAT_ROOT_PATH}api/v1/rejectreason/getAllRejectInfoLabels`,
  CHECK_GET_HISTORY_LIST_DATA: `${MAAT_ROOT_PATH}api/v1/qualificationCheck/search`,
  CHECK_GET_COUNT_REVIEWER_DATA: `${MAAT_ROOT_PATH}api/v1/qualificationCheck/countCheckNumber`,

  // 仅投联盟
  UNION_INDUSTRY_IDS: `${MAAT_ROOT_PATH}api/v1/onlyForUnion/getAllIndustryIds`,
  UNION_INDUSTRY_INFO: `${MAAT_ROOT_PATH}api/v1/onlyForUnion/getAllIndustryInfo`,
  UNION_ADD_INDUSTRY: `${MAAT_ROOT_PATH}api/v1/onlyForUnion/addOneIndustry`,
  UNION_REMOVE_INDUSTRY: `${MAAT_ROOT_PATH}api/v1/onlyForUnion/removeOneIndustry`,
  CHECK_GET_REVIEW_LOG: `${MAAT_ROOT_PATH}api/v1/creativeReview/getReviewLogByTaggedId`,
  CHECK_DO_REVIEW: `${MAAT_ROOT_PATH}api/v1/qualificationCheck/doReview`,
  CHECK_DO_BATCH_REVIEW: `${MAAT_ROOT_PATH}api/v1/qualificationCheck/doBatchReview`,
  CHECK_SEARCH_BY_IDS: `${MAAT_ROOT_PATH}api/v1/qualificationCheck/searchByIds`,

  // 黑图库
  SEARCH_BLACK_IMAGE: `${MAAT_ROOT_PATH}api/v1/blackImage/search`,
  DELETE_BLACK_IMAGE: `${MAAT_ROOT_PATH}api/v1/blackImage/delete`,
  UPLOAD_BLACK_IMAGE: `${MAAT_ROOT_PATH}api/v1/blackImage/uploadImage`,
  UPDATE_BLACK_IMAGE: `${MAAT_ROOT_PATH}api/v1/blackImage/updateImage`,
  BLACK_IMAGE_INIT: `${MAAT_ROOT_PATH}api/v1/blackImage/init`,

  // dpa商品查询
  INIT_DPA_SEARCH_DATA: `${MAAT_ROOT_PATH}api/v1/dpaProduct/initData`,
  GET_DPA_PRODUCT_TYPE_LIST: `${MAAT_ROOT_PATH}api/v1/dpaProduct/getProductTypeList`,
  GET_DPA_SIMILAR_IMAGES: `${MAAT_ROOT_PATH}api/v1/dpaProduct/getSimilarImageList`,
  DPA_PRODUCT_SEARCH: `${MAAT_ROOT_PATH}api/v1/dpaProduct/search`,
  DPA_DO_REVIEW: `${MAAT_ROOT_PATH}api/v1/dpaProduct/productReview`,
  DPA_GET_REVIEW_HISTORY: `${MAAT_ROOT_PATH}api/v1/dpaProduct/getProductReviewLog`,
  DPA_GET_NOVEL_CHAPTER: `${MAAT_ROOT_PATH}api/v1/dpaProduct/novelchapter`,

  // spu查询
  GET_SPU_TYPE_LIST: `${MAAT_ROOT_PATH}api/v1/spu/cateinfo/search`,
  SEARCH_SPU: `${MAAT_ROOT_PATH}api/v1/spu/catalog/search`,
  GET_SPU_REVIEW_HISTORY: `${MAAT_ROOT_PATH}api/v1/spu/reviewlog/search`,
  SPU_DO_REVIEW: `${MAAT_ROOT_PATH}api/v1/spu/catalog/audit`,
  SPU_DO_BATCH_REVIEW: `${MAAT_ROOT_PATH}api/v1/spu/catalog/multiAudit`,

  // dpa商品库查询
  DPA_CATALOG_SEARCH: `${MAAT_ROOT_PATH}api/v1/dpaCatalog/search`,
  DPA_CATALOG_RISK_MARK: `${MAAT_ROOT_PATH}api/v1/dpaCatalog/catalogUpdate`,
  DPA_CATALOG_REVIEW: `${MAAT_ROOT_PATH}api/v1/dpaCatalog/catalogReview`,
  DPA_CATALOG_GET_REVIEW_HISTORY: `${MAAT_ROOT_PATH}api/v1/dpaCatalog/getCatalogReviewLog`,
  DPA_CATALOG_POLL_MESSAGE: `${MAAT_ROOT_PATH}api/v1/dpaCatalog/catalogMessage`,

  // dpa商品库评级
  DPA_LEVEL_SEARCH: `${MAAT_ROOT_PATH}api/v1/dpaCatalogGradent/search`,
  DPA_LEVEL_DOWNLOAD: `${MAAT_ROOT_PATH}api/v1/dpaCatalogGradent/downRejectList`,
  DPA_LEVEL_GRADIENT: `${MAAT_ROOT_PATH}api/v1/dpaCatalogGradent/gradent`,
  DPA_LEVEL_INPUT: `${MAAT_ROOT_PATH}api/v1/dpaCatalogGradent/input`,

  // 外链标的物审核
  EXTERNAL_LINK_REVIEW_SEARCH: `${MAAT_ROOT_PATH}api/v1/authorizedDomainQuery/search`,
  EXTERNAL_LINK_REVIEW_IMAGE_UPLOAD: `${MAAT_ROOT_PATH}api/v1/authorizedDomainQuery/uploadSpeEmailImage`,
  EXTERNAL_LINK_GET_REVIEW_HISTORY: `${MAAT_ROOT_PATH}api/v1/authorizedDomainQuery/getReviewLogs`,
  EXTERNAL_LINK_DO_REVIEW: `${MAAT_ROOT_PATH}api/v1/authorizedDomainQuery/doReview`,
  EXTERNAL_LINK_FILE_UPLOAD: `${MAAT_ROOT_PATH}api/v1/authorizedDomainQuery/fileUpload`,

  // 基础元素审核与查询
  BASE_ELEMENT_GET_INIT_DATA: `${MAAT_ROOT_PATH}api/v1/base_element/getInitData`,
  BASE_ELEMENT_TAKE_TASK: `${MAAT_ROOT_PATH}api/v1/base_element/takeTasks`,
  BASE_ELEMENT_SEARCH: `${MAAT_ROOT_PATH}api/v1/base_element/search`,
  BASE_ELEMENT_GET_LOG: `${MAAT_ROOT_PATH}api/v1/base_element/getReviewLogs`,
  BASE_ELEMENT_SAVE_REVIEW_STATUS: `${MAAT_ROOT_PATH}api/v1/base_element/saveReviewStatus`,
  BASE_ELEMENT_GET_LATEST_REASONS: `${MAAT_ROOT_PATH}api/v1/base_element/getLatestReasons`,
  BASE_ELEMENT_GET_TASK_QUEUE_INFO: `${MAAT_ROOT_PATH}api/v1/base_element/getTaskQueueInfo`,
  BASE_ELEMENT_RETURN_TASK: `${MAAT_ROOT_PATH}api/v1/base_element/returnTask`,
  BASE_ELEMENT_DELETE_TASK: `${MAAT_ROOT_PATH}api/v1/base_element/deleteTask`,
  MATERIAL_ELEMENT_GET_LOGS: `${MAAT_ROOT_PATH}api/v1/base_element/material/getReviewLogs`,
  REWARD_VIDEO_TAKE_TASK: `${MAAT_ROOT_PATH}api/v1/rewardVideo/takeTasks`,
  REWARD_VIDEO_REVIEW_HISTORY: `${MAAT_ROOT_PATH}api/v1/rewardVideo/getReviewLogs`,
  REWARD_VIDEO_DO_REVIEW: `${MAAT_ROOT_PATH}api/v1/rewardVideo/doVideoReview`,
  REWARD_VIDEO_SEARCH: `${MAAT_ROOT_PATH}api/v1/rewardQuestVideo/search`,
  REWARD_VIDEO_TASK_QUEUE: `${MAAT_ROOT_PATH}api/v1/rewardQuestVideo/getTaskQueueInfo`,
  REWARD_VIDEO_RETURN_TASK: `${MAAT_ROOT_PATH}api/v1/rewardQuestVideo/returnTask`,
  BASE_REVIEW_SEARCH_COST: `${MAAT_ROOT_PATH}api/v1/creativeReview/baseReviewSearchCost`,
  BASE_ELEMENT_VIDEO_SEARCH: `${MAAT_ROOT_PATH}api/v1/component/search`,
  BASE_ELEMENT_VIDEO_Log: `${MAAT_ROOT_PATH}api/v1/component/searchReviewLog`,
  BASE_ELEMENT_VIDEO_REASONS: `${MAAT_ROOT_PATH}api/v1/component/getLatestReasons`,
  BASE_ELEMENT_VIDEO_DO_REVIEW: `${MAAT_ROOT_PATH}api/v1/component/review`,
  BASE_ELEMENT_VIDEO_DO_REVIEW_MULTI: `${MAAT_ROOT_PATH}api/v1/component/reviewMulti`,
  BASE_ELEMENT_VIDEO_TAKE_TASK: `${MAAT_ROOT_PATH}api/v1/component/takeTask`,
  LOCAL_STORE_SEARCH: `${MAAT_ROOT_PATH}api/v1/shop/search`,
  LOCAL_STORE_REVIEW: `${MAAT_ROOT_PATH}api/v1/shop/review`,
  LOCAL_STORE_HISTORY: `${MAAT_ROOT_PATH}api/v1/shop/getShopReviewInfoLog`,

  // 规则配置
  RULE_CONFIG_GET_RULES: `${MAAT_ROOT_PATH}api/v1/ruleConfig/getRules`,
  RULE_CONFIG_GET_AI_CONFIGS: `${MAAT_ROOT_PATH}api/v1/ruleConfig/getAIConfigs`,

  // vip manage
  VIP_MANAGE_COST_SCORE: `${MAAT_ROOT_PATH}api/AdvertiserManaulCostScoreConfig`,
  BASE_VIP_QUERY: `${MAAT_ROOT_PATH}api/v1/AdvertiserLandingPageVipConfig/query`,
  BASE_VIP_DELETE: `${MAAT_ROOT_PATH}api/v1/AdvertiserLandingPageVipConfig/delete`,
  BASE_VIP_ADD: `${MAAT_ROOT_PATH}api/v1/AdvertiserLandingPageVipConfig/add`,

  // 批量冻结
  BATCH_FREEZE_MANAGE_SUBMIT: `${MAAT_ROOT_PATH}api/v1/advertiserReview/batchFreeze`,
  BATCH_OFFLINE_LANDING_PAGE: `${MAAT_ROOT_PATH}api/v1/landingPage/batchOfflineLandingPage`,
  BATCH_OFFLINE_PRODUCT: `${MAAT_ROOT_PATH}api/v1/landingPage/batchOfflineProduct`,

  // mrd punishment
  FETCH_PUNISH_RULE_AND_OTHER_INFO: `${MAAT_ROOT_PATH}api/v1/punishmentController/getInitData`,
  GET_HISTORY: `${MAAT_ROOT_PATH}api/v1/punishmentController/history`,
  ADD_PUNISH_RULE: `${MAAT_ROOT_PATH}api/v1/punishmentController/addScene`,
  DELETE_PUNISH_RULE: `${MAAT_ROOT_PATH}api/v1/punishmentController/deleteScene`,
  ADD_PUNISH_RECEIPT: `${MAAT_ROOT_PATH}api/v1/punishmentController/addPunishment`,
  PUNISH_RECEIPT_SEARCH: `${MAAT_ROOT_PATH}api/v1/punishmentController/search`,
  PUNISH_SUBMIT: `${MAAT_ROOT_PATH}api/v1/punishmentController/handle`,
  BATCH_DEAL_SUBMIT: `${MAAT_ROOT_PATH}api/v1/punishmentController/batchHandle`,
  FETCH_UID_PUNISH_MONEY_TYPE: `${MAAT_ROOT_PATH}api/v1/punishmentController/getDepositTypeByUid`,
  FETCH_MP_PUNISH_TYPE: `${MAAT_ROOT_PATH}api/v1/mpPunishment/getIllegalList`,
  FETCH_MP_PUNISH_COUNT: `${MAAT_ROOT_PATH}api/v1/mpPunishment/getIllegalCount`,
  FETCH_MP_ACCOUNT_AGENCIES: `${MAAT_ROOT_PATH}api/v1/mpPunishment/getAgencies`,
  GET_SPID_BY_AID: `${MAAT_ROOT_PATH}api/v1/mpPunishment/getAgencyIdByAid`,
  ADD_MP_PUNISH: `${MAAT_ROOT_PATH}api/v1/mpPunishment/add`,
  CANCEL_PUNISHMENT: `${MAAT_ROOT_PATH}api/v1/mpPunishment/cancel`,

  // apk review
  APK_REVIEW_SEARCH: `${MAAT_ROOT_PATH}api/v1/apk_element/search`,
  APK_REVIEW_SUBMIT: `${MAAT_ROOT_PATH}api/v1/apk_element/saveReviewStatus`,
  APK_REVIEW_GET_REVIEW_HISTORY: `${MAAT_ROOT_PATH}api/v1/apk_element/getReviewLogs`,
  APK_SEARCH_BATCH_AUDIT: `${MAAT_ROOT_PATH}api/v1/apk_element/saveMultiReviewStatus`,
  APK_TAKE_TASK: `${MAAT_ROOT_PATH}api/v1/apk_element/takeTask`,
  APK_GET_LATEST_USE_REASON: `${MAAT_ROOT_PATH}api/v1/apk_element/getLatestReasons`,
  APK_FILE_UPLOAD: `${MAAT_ROOT_PATH}api/v1/apk_element/fileUpload`,
  APK_SAVE_BREAK_RULE_PICS: `${MAAT_ROOT_PATH}api/v1/apk_element/saveBreakRulePics`,
  APK_SAVE_CHECK_REVIEWER: `${MAAT_ROOT_PATH}api/v1/apk_element/checkReviewer`,

  // APK巡查
  APK_INSPECT_SEARCH: `${MAAT_ROOT_PATH}api/v1/apkInspectInvoice/searchTask`,
  APK_INSPECT_TAKE_TASK: `${MAAT_ROOT_PATH}api/v1/apkInspectInvoice/takeTask`,
  APK_INSPECT_RETURN_TASK: `${MAAT_ROOT_PATH}api/v1/apkInspectInvoice/returnTask`,
  APK_INSPECT_FETCH_INITIAL_DATA: `${MAAT_ROOT_PATH}api/v1/apkInspectInvoice/getCurrentProcess`,
  APK_INSPECT_VALID_REVIEW: `${MAAT_ROOT_PATH}api/v1/apkInspectInvoice/validReview`,
  APK_INSPECT_INVALID_REVIEW: `${MAAT_ROOT_PATH}api/v1/apkInspectInvoice/invalidReview`,

  // BM账户查询
  BM_ACCOUNT_SEARCH: `${MAAT_ROOT_PATH}api/v1/BmAdvertiserReviewController/query`,
  BM_ACCOUNT_REVIEW: `${MAAT_ROOT_PATH}api/v1/BmAdvertiserReviewController/review`,
  BM_ACCOUNT_FETCH_HISTORY: `${MAAT_ROOT_PATH}api/v1/BmAdvertiserReviewController/getReviewHistory`,

  // 资质
  FETCH_CERTIFICATIONS: `${MAAT_ROOT_PATH}api/v1/certificationV2/queryCertifications`,
  CERTIFICATION_AUDIT_SUBMIT: `${MAAT_ROOT_PATH}api/v1/certificationV2/reviewCertification`,
  FETCH_ALL_CERTIFICATION_NAME: `${MAAT_ROOT_PATH}api/v1/certificationV2/queryAllNames`,
  FETCH_CERTIFICATION_REVIEW_LOG: `${MAAT_ROOT_PATH}api/v1/certificationV2/queryReviewHistory`,
  // 二审
  PLAY_REVIEW_SEARCH: `${MAAT_ROOT_PATH}api/v1/creativeTrafficGroupReview/search`,
  PLAY_REVIEW_TAKETASK: `${MAAT_ROOT_PATH}api/v1/creativeTrafficGroupReview/fetchUserTasks`,
  PLAY_REVIEW_SET_DISPATCH_RULE: `${MAAT_ROOT_PATH}api/v1/creativeTrafficGroupReview/setDispatchRule`,
  PLAY_REVIEW_AUDIT_SUBMIT: `${MAAT_ROOT_PATH}api/v1/creativeTrafficGroupReview/doReview`,
  PLAY_REVIEW_BATCH_AUDIT: `${MAAT_ROOT_PATH}api/v1/creativeTrafficGroupReview/doBatchReview`,
  PLAY_REVIEW_READ_SUBMIT: `${MAAT_ROOT_PATH}api/v1/creativeTrafficGroupReview/view`,
  PLAY_REVIEW_BATCH_READ: `${MAAT_ROOT_PATH}api/v1/creativeTrafficGroupReview/viewBatch`,
  PLAY_REVIEW_GET_REVIEW_LOGS: `${MAAT_ROOT_PATH}api/v1/creativeTrafficGroupReview/getLogs`,
  PLAY_REVIEW_FETCH_INITIAL_DATA: `${MAAT_ROOT_PATH}api/v1/creativeTrafficGroupReview/getSearchInitData`,
  PLAY_REVIEW_FETCH_DISPATCH_INIT_DATA: `${MAAT_ROOT_PATH}api/v1/creativeTrafficGroupReview/getDispatchInitData`,
  PLAY_REVIEW_CREATE_OFFLINE_TASK: `${MAAT_ROOT_PATH}api/v1/creativeTrafficGroupReview/createOfflineTask`,
  PLAY_REVIEW_FETCH_INITIAL_OFFLINE_TASK: `${MAAT_ROOT_PATH}api/v1/creativeTrafficGroupReview/getAllTaskStatus`,
  PLAY_REVIEW_SEARCH_OFFLINE_TASK: `${MAAT_ROOT_PATH}api/v1/creativeTrafficGroupReview/queryOfflineTask`,
  PLAY_REVIEW_OFFLINE_TASK_REJECT_SUBMIT: `${MAAT_ROOT_PATH}api/v1/creativeTrafficGroupReview/executeOfflineTask`,
  PLAY_REVIEW_DELETE_OFFLINE_TASK: `${MAAT_ROOT_PATH}api/v1/creativeTrafficGroupReview/deleteOfflineTask`,
  PLAY_REVIEW_GET_FAILED_TID_LIST: `${MAAT_ROOT_PATH}api/v1/creativeTrafficGroupReview/getFailedTidsOfTask`,
  PLAY_REVIEW_GET_SUCCESS_TID_LIST: `${MAAT_ROOT_PATH}api/v1/creativeTrafficGroupReview/getSuccessTidsOfTask`,
  PLAY_REVIEW_MARK_TAG: `${MAAT_ROOT_PATH}api/v1/creativeTrafficGroupReview/doTag`,
  PLAY_REVIEW_BATCH_MARK_TAG: `${MAAT_ROOT_PATH}api/v1/creativeTrafficGroupReview/doBatchTag`,
  PLAY_REVIEW_RESET_TAKETASK_DATA: `${MAAT_ROOT_PATH}api/v1/creativeTrafficGroupReview/getDispatchRuleDetail`,

  // 负反馈
  FEEDBACK_API: `${FEEDBACK_ROOT_PATH}api/feedback/task`,
  FEEDBACK_SEARCH_INIT: `${FEEDBACK_ROOT_PATH}api/feedback/init`,
  FEEDBACK_BATCH_DEAL: `${FEEDBACK_ROOT_PATH}api/feedback/batchtask`,
  FEEDBACK_RANK_SEARCH: `${MAAT_ROOT_PATH}api/v1/creativeReview/searchForFeedbackRanking`,
  FEEDBACK_RANK_REASON: `${FEEDBACK_ROOT_PATH}api/feedback/ranking/reasonCode`, // 投诉理由分布
  FEEDBACK_MARK_NOT_NEED_TO_HANDLE: `${MAAT_ROOT_PATH}api/v1/proxy/feedbackMarkNotNeedToHandle`, // 投诉理由分布

  // 竞品查询
  COMPETING_SEARCH_BY_CONDITION: `${MAAT_ROOT_PATH}api/v1/limitedCompetitionProductController/search`,
  COMPETING_SEARCH_ALL: `${MAAT_ROOT_PATH}api/v1/limitedCompetitionProductController/getAll`,
  COMPETING_PRODUCT_ADD: `${MAAT_ROOT_PATH}api/v1/limitedCompetitionProductController/create`,
  COMPETING_PRODUCT_DELETE: `${MAAT_ROOT_PATH}api/v1/limitedCompetitionProductController/delete`,
  COMPETING_PRODUCT_UPDATE: `${MAAT_ROOT_PATH}api/v1/limitedCompetitionProductController/update`,

  // 账户黑名单
  ADVERTISER_BLACKLIST_SEARCH: `${MAAT_ROOT_PATH}api/v1/advertiserBlackList/search`,
  ADVERTISER_BLACKLIST_ADD: `${MAAT_ROOT_PATH}api/v1/advertiserBlackList/addBlackList`,
  ADVERTISER_BLACKLIST_DELETE: `${MAAT_ROOT_PATH}api/v1/advertiserBlackList/deleteBlackList`,
  ADVERTISER_BLACKLIST_DOWNLOAD: `${MAAT_ROOT_PATH}api/v1/advertiserBlackList/downloadBlackList`,

  // OTT
  QUERY_OTT_REVIEW_INFO: `${MAAT_ROOT_PATH}api/v1/ott/getOttReviewInfo`,

  // 敏感词管理
  LIMITED_WORD_GET_USAGE_MODE: `${MAAT_ROOT_PATH}api/v1/limitedWord/getUsageMode`,
  LIMITED_WORD_GET_LIMITED_WORD_TYPE: `${MAAT_ROOT_PATH}api/v1/limitedWord/getLimitedWordType`,
  LIMITED_WORD_QUERY: `${MAAT_ROOT_PATH}api/v1/limitedWord/query`,
  LIMITED_WORD_DELETE: `${MAAT_ROOT_PATH}api/v1/limitedWord/delete`,
  LIMITED_WORD_DOWNLOAD: `${MAAT_ROOT_PATH}api/v1/limitedWord/download`,
  LIMITED_WORD_DOWNLOAD_FILE: `${MAAT_ROOT_PATH}api/v1/limitedWord/downloadFile`,
  LIMITED_WORD_ADD: `${MAAT_ROOT_PATH}api/v1/limitedWord/add`,
  LIMITED_WORD_UPDATE: `${MAAT_ROOT_PATH}api/v1/limitedWord/update`,
  LIMITED_WORD_USE_SCENE_QUERY: `${MAAT_ROOT_PATH}api/v1/limitedWordUseScene/query`,
  LIMITED_WORD_USE_SCENE_ADD: `${MAAT_ROOT_PATH}api/v1/limitedWordUseScene/addToLimitedWord`,
  LIMITED_WORD_USE_SCENE_UPDATE: `${MAAT_ROOT_PATH}api/v1/limitedWordUseScene/update`,
  LIMITED_WORD_USE_SCENE_DELETE: `${MAAT_ROOT_PATH}api/v1/limitedWordUseScene/delete`,
  LIMITED_WORD_TYPE_DELETE: `${MAAT_ROOT_PATH}api/v1/limitedWordType/delete`,
  LIMITED_WORD_TYPE_ADD: `${MAAT_ROOT_PATH}api/v1/limitedWordType/add`,
  LIMITED_WORD_BATCH_ADD: `${MAAT_ROOT_PATH}api/v1/limitedWord/multiAdd`,
  LIMITED_WORD_BATCH_UPDATE_SCENE: `${MAAT_ROOT_PATH}api/v1/limitedWord/multiUpdate`,
  LIMITED_WORD_BATCH_UPDATE_WORD: `${MAAT_ROOT_PATH}api/v1/limitedWord/multiUpdateWord`,

  // 系统融合-名单管理
  REVIEW_WHITE_BLACK_LIST_MANAGE_SEARCH_CONFIG: `${MAAT_ROOT_PATH}api/v1/basicConfigManage/searchBasicConfigInfo`,
  REVIEW_WHITE_BLACK_LIST_MANAGE_SEARCH_LIST_TABLE: `${MAAT_ROOT_PATH}api/v1/basicConfigManage/searchBasicConfigItem`,
  REVIEW_WHITE_BLACK_LIST_MANAGE_UPDATE_CONFIG: `${MAAT_ROOT_PATH}api/v1/basicConfigManage/updateBasicConfigInfo`,
  REVIEW_WHITE_BLACK_LIST_MANAGE_DELETE_LIST_CONTENT_ITEM: `${MAAT_ROOT_PATH}api/v1/basicConfigManage/deleteBasicConfigItem`,
  REVIEW_WHITE_BLACK_LIST_MANAGE_FETCH_ALL_LIST_DATA: `${MAAT_ROOT_PATH}api/v1/basicConfigManage/getAllBasicConfigInfo`,
  REVIEW_WHITE_BLACK_LIST_MANAGE_ADD_LIST_CONTENT_ITEM: `${MAAT_ROOT_PATH}api/v1/basicConfigManage/batchCreateBasicConfigItemByContent`,
  REVIEW_WHITE_BLACK_LIST_MANAGE_EDIT_LIST_CONTENT_ITEM: `${MAAT_ROOT_PATH}api/v1/basicConfigManage/updateBasicConfigItem`,

  // 私域机器人
  PRIVATE_DOMAIN_ROBOT_SEARCH_TASK: `${MAAT_ROOT_PATH}api/v1/patrol/chatInspect/searchTask`,
  PRIVATE_DOMAIN_ROBOT_REVIEW_TASK: `${MAAT_ROOT_PATH}api/v1/patrol/chatInspect/review`,

  // 风险控制
  PATROL_REVIEW: `${MAAT_ROOT_PATH}api/v1/patrol/task/review`,
  LANDING_PAGE_PATROL_TASK_SEARCH: `${MAAT_ROOT_PATH}api/v1/patrol/task/search`,
  PATROL_TASK_TAKE: `${MAAT_ROOT_PATH}api/v1/patrol/task/take`,
  PATROL_BATCH_REVIEW: `${MAAT_ROOT_PATH}api/v1/patrol/task/batchReview`,
  PATROL_RELEASE: `${MAAT_ROOT_PATH}api/v1/patrol/task/release`,
  PATROL_GET_UID_INFO: `${MAAT_ROOT_PATH}api/v1/patrol/task/queryPunishUidInfo`,
  PATROL_QUERYRELATEDADS: `${MAAT_ROOT_PATH}api/v1/patrol/task/queryRelatedAds`,
  GET_VIOLATIONS_DETAILS: `${MAAT_ROOT_PATH}api/v1/patrol/task/getViolationsByObjectID`,
  GET_RELATED_KEYWORDS: `${MAAT_ROOT_PATH}api/v1/patrol/task/queryRelatedKeyWords`,
  GET_RELATED_COMPONENTS: `${MAAT_ROOT_PATH}api/v1/patrol/task/queryRelatedComponents`,
  GET_RELATED_PUNISH_UID: `${MAAT_ROOT_PATH}api/v1/patrol/task/queryRelatedPunishUidInfo`,

  // 小程序巡查
  MINI_PROGRAM_PATROL_QRCODE: `${MAAT_ROOT_PATH}api/v1/patrol/task/genPreviewCodeImg`,

  // 快应用巡查
  QUICK_APP_PATROL_VIOLATION_DETAIL: `${MAAT_ROOT_PATH}api/v1/patrol/task/violationDetail`,
};

export default PATH;

export const LOGIN_HREF = `${MAAT_ROOT_PATH}api/v1/auth/login`;
export const LOGIN_HREF_QQ = `${MAAT_ROOT_PATH}api/v1/auth/loginV2`;

export const FEEDBACK_LOGIN_INFO = `${FEEDBACK_ROOT_PATH}api/v1/auth/login_info`;
export const FEEDBACK_LOGIN_HREF = `${FEEDBACK_ROOT_PATH}api/v1/auth/login`;

// 零余额 延迟队列aid查询
export const DELAY_TASK_SEARCH = `${MAAT_ROOT_PATH}api/v1/reviewInvoiceDelay/hitResult`;

export const AI_RULE_CONFIG = {
  GET_ALL_ABILITY_LIST: `${MAAT_ROOT_PATH}api/v1/ruleservice/manage/getAbilityFiledMetaInfo`,
  FETCH_AI_CONFIG_LIST: `${MAAT_ROOT_PATH}api/v1/ruleservice/manage/searchAbilityConfig`,
  FETCH_REJECT_LABEL: `${MAAT_ROOT_PATH}api/v1/ruleservice/manage/getAllRejectLabel`,
  FETCH_SCENE: `${MAAT_ROOT_PATH}api/v1/ruleservice/manage/getAllRuleScene`,
  FETCH_RULE_GROUP: `${MAAT_ROOT_PATH}api/v1/ruleservice/manage/getAllRuleGroup`,
  FETCH_RULE_STRAGEGY: `${MAAT_ROOT_PATH}api/v1/ruleservice/manage/getAllRuleStrategy`,
  FETCH_RULE_STATIC_META_INFO: `${MAAT_ROOT_PATH}api/v1/ruleservice/manage/getStaticMetaInfo`,
  RULE_CONFIG_SEARCH: `${MAAT_ROOT_PATH}api/v1/ruleservice/manage/searchRule`,
  FETCH_ALL_AI_CONFIG_LIST: `${MAAT_ROOT_PATH}api/v1/ruleservice/manage/getAllAbilityConfig`,
  MODIFY_AI_CONFIG: `${MAAT_ROOT_PATH}api/v1/ruleservice/manage/modifyAbilityConfig`,
  MODIFY_RULE_CONFIG: `${MAAT_ROOT_PATH}api/v1/ruleservice/manage/modifyRuleConfig`,
  DELETE_RULE_CONFIG: `${MAAT_ROOT_PATH}api/v1/ruleservice/manage/deleteRuleById`,
  CREATE_RULE_CONFIG: `${MAAT_ROOT_PATH}api/v1/ruleservice/manage/addRuleConfig`,
  CREATE_AI_CONFIG: `${MAAT_ROOT_PATH}api/v1/ruleservice/manage/addAbilityConfig`,
  DEAL_RULE_STATUS: `${MAAT_ROOT_PATH}api/v1/ruleservice/manage/updateRuleSysStatus`,
  GET_ALL_INDUSTRY_LIST: `${MAAT_ROOT_PATH}api/v1/ruleservice/manage/field/industry`,
};

export const CREATIVE_OFFLINE_TASK = {
  CREATE_TASK: `${MAAT_ROOT_PATH}api/v1/preOffline/createOfflineTask`,
  SEARCH_TASK: `${MAAT_ROOT_PATH}api/v1/preOffline/queryOfflineTask`,
  MODIFY_TASK: `${MAAT_ROOT_PATH}api/v1/preOffline/updateOfflineTask`,
  EXECUTE_TASK_NOW: `${MAAT_ROOT_PATH}api/v1/preOffline/executeOfflineTask`,
  FETCH_TASK_DETAIL: `${MAAT_ROOT_PATH}api/v1/preOffline/getOfflineTaskDetail`,
  CANCEL_TASK: `${MAAT_ROOT_PATH}api/v1/preOffline/terminateOfflineTask`,
  DELETE_TASK_TID: `${MAAT_ROOT_PATH}api/v1/preOffline/deleteOfflineTaskDetail`,
  UPLOAD_FILE: `${MAAT_ROOT_PATH}api/v1/preOffline/fileUpload`,
  DOWNLOAD_TASK_DETAIL: `${MAAT_ROOT_PATH}api/v1/preOffline/downloadTaskDetail`,
};

export const MONITOR_DATA = {
  VIP_CUSTOMER_DAY_MONITOR_FETCH_REAL_TIME_DATA: `${MAAT_ROOT_PATH}api/v1/leReport/getRealtimeReportData`,
  VIP_CUSTOMER_DAY_DATA_SEARCH: `${MAAT_ROOT_PATH}api/v1/leReport/searchHistoryData`,
};

export const DAY_MONITOR = {
  CUSTOMER_TIMEOUT: `${MAAT_ROOT_PATH}api/v1/review_kanban/getTimeoutCustomerPending`,
  CUSTOMER_TOP_TASK: `${MAAT_ROOT_PATH}api/v1/review_kanban/getTopPushCustomer`,
  CUSTOMER_TOP_REJECT: `${MAAT_ROOT_PATH}api/v1/review_kanban/getTopRefuseCustomer`,
  CUSTOMER_LOW_AUTO_AUDIT: `${MAAT_ROOT_PATH}api/v1/review_kanban/getBottomAutoReviewCustomer`,
  CHANNEL_OVERVIEW: `${MAAT_ROOT_PATH}api/v1/review_kanban/getReviewInChannel`,
  LAST_WEEK_TOP_MONEY_CUSTOMER: `${MAAT_ROOT_PATH}api/v1/review_kanban/getReviewForTopExpendCustomer`,
};

// 强拉预览
export const PULL_WX_SIMULATE_CONFIG = `${MAAT_ROOT_PATH}api/v1/creativeReview/queryWxVideoList`;
export const SET_WX_SIMULATE_CONFIG = `${MAAT_ROOT_PATH}api/v1/creativeReview/addWxVideoList`;
export const FETCH_WECHAT_SUBSCRIPTION_ACCOUNT_INFO = `${MAAT_ROOT_PATH}api/v1/creativeReview/queryGzhContent`;
export const FETCH_ADQ_WECHAT_SUBSCRIPTION_ACCOUNT_INFO = `${MAAT_ROOT_PATH}api/v1/creativeReview/queryGzhContentByWxAppId`;
export const FETCH_WECHAT_SUBSCRIPTION_ACCOUNT_ARTICLE = `${MAAT_ROOT_PATH}api/v1/creativeReview/queryGzhPage`;
export const UPLOAD_IMG_TO_CORS = `${MAAT_ROOT_PATH}api/v1/creativeReview/uploadReviewLocationPic`;

// 先发后审
export const CREATIVE_LATER_REVIEW = {
  TAKE_TASK: `${MAAT_ROOT_PATH}api/v1/againReview/takeTasks`,
  DO_OPERATE: `${MAAT_ROOT_PATH}api/v1/againReview/doOperate`,
  FORBID: `${MAAT_ROOT_PATH}api/v1/creative/forbid`,
  DO_BATCH_OPERATE: `${MAAT_ROOT_PATH}api/v1/againReview/doOperateBatch`,
};


export const getSpecialRejectAd = `${MAAT_ROOT_PATH}api/v1/creativeReview/getSpecialRejectAd`;


export const CREATIVE_LATER_REVIEW_TASK_INFO = {
  FETCH_CHANNEL_STATIC_TASK: `${MAAT_ROOT_PATH}api/v1/againReview/channelStaticInfo`,
  FETCH_CHANNEL_LEFT_TASK: `${MAAT_ROOT_PATH}api/v1/againReview/allChannelReviewTaskInfo`,
  FETCH_REVIEWER_TASK: `${MAAT_ROOT_PATH}api/v1/againReview/allReviewerTaskInfo`,
  RETURN_TASK: `${MAAT_ROOT_PATH}api/v1/againReview/returnReviewerTask`,
};

export const CREATIVE_YYB_PREVIEW_URL = `${MAAT_ROOT_PATH}api/v1/showYybDetailPage`;
export const CREATIVE_XI_PREVIEW_URL = `${MAAT_ROOT_PATH}api/v1/toXJPreviewPage`;
export const CREATIVE_MDPA_PREVIEW_URL = `${MAAT_ROOT_PATH}api/v1/replaceLandingPageWildcard`;

export const PRESS_AUDIT_VIP_CONFIG = {
  CREATE_CONFIG: `${MAAT_ROOT_PATH}api/v1/UrgeReviewConfig/add`,
  SEARCH: `${MAAT_ROOT_PATH}api/v1/UrgeReviewConfig/query`,
  UPDATE_CONFIG: `${MAAT_ROOT_PATH}api/v1/UrgeReviewConfig/update`,
  DELETE_CONFIG: `${MAAT_ROOT_PATH}api/v1/UrgeReviewConfig/delete`,
};

export const PRESS_AUDIT_TASK = {
  SEARCH: `${MAAT_ROOT_PATH}api/v1/UrgeReviewCreative/query`,
};

export const PICK_CREATIVE = {
  SEARCH: `${MAAT_ROOT_PATH}api/v1/videoElectionController/queryAdList`,
  REVIEW: `${MAAT_ROOT_PATH}api/v1/videoElectionController/doReview`,
  FETCH_REVIEW_HISTORY: `${MAAT_ROOT_PATH}api/v1/videoElectionController/queryReviewLog`,
  FETCH_PREVIEW_KEY: `${MAAT_ROOT_PATH}api/v1/videoElectionController/queryPreviewKey`,
};

export const LITTLE_TASK = {
  TAKE_TASK: `${MAAT_ROOT_PATH}api/v1/rewardJob/takeTasks`,
  TASK_DETAIL_AD_REVIEW: `${MAAT_ROOT_PATH}api/v1/rewardJob/doAdGroupReview`,
  TASK_DETAIL_CREATIVE_REVIEW: `${MAAT_ROOT_PATH}api/v1/rewardJob/doCreativeReview`,
};


export const LINGKUN_AUDIT = {
  SEARCH_TASK_LIST: `${MAAT_ROOT_PATH}api/v1/proxy/lkReviewAdSearch`,
  TAKE_TASK: `${MAAT_ROOT_PATH}api/v1/proxy/lkReviewTakeTask`,
  CANCEL_TASK: `${MAAT_ROOT_PATH}api/v1/proxy/lkReviewCancelTask`,
  REVIEW_TASK: `${MAAT_ROOT_PATH}api/v1/proxy/lkReviewAdAudit`,
  SEARCH_HISTROY_LOG: `${MAAT_ROOT_PATH}api/v1/proxy/lkReviewSearchLog`,
  GET_LK_BASE_INFO: `${MAAT_ROOT_PATH}api/v1/proxy/lkReviewGetBaseInfo`,
  UPDATE_LK_AD_INFO: `${MAAT_ROOT_PATH}api/v1/proxy/lkReviewAdUpdate`,
  // 人工上传
  MANUAL_UPLOAD_PIC: `${MAAT_ROOT_PATH}api/v1/proxy/lkReviewUploadFile`,
  MANUAL_UPLOAD_INFO: `${MAAT_ROOT_PATH}api/v1/proxy/lkReviewManualUpload`,
  // 标记图片
  LK_UPDATE_SNAPSHOT: `${MAAT_ROOT_PATH}api/v1/proxy/lkReviewUpdateSnapshot`,
  // 补充电商平台广告主信息
  LK_QUERY_AD_COLLECTION: `${MAAT_ROOT_PATH}api/v1/proxy/lkReviewQueryAdCollection`,
  LK_UPDATE_AD_COLLECTION: `${MAAT_ROOT_PATH}api/v1/proxy/lkReviewUpdateAdCollection`,
  LK_QUERY_AD_COLLECTION_LOG: `${MAAT_ROOT_PATH}api/v1/proxy/lkReviewQueryAdCollectionLog`, 
  // 查询推送数据并展示
  LK_QUERY_AD_FINAL_RESULT: `${MAAT_ROOT_PATH}api/v1/proxy/lkReviewQueryAdFinalResult`,
  LK_AUDIT_AD_FINAL_RESULT_BATCH: `${MAAT_ROOT_PATH}api/v1/proxy/lkReviewAuditAdFinalResultBatch`,
};

export const FETCH_COS_TOKEN = `${MAAT_ROOT_PATH}api/v1/cosToken/getCredential`;
export const QUERY_REVIEW_USER_PRIVILEGE_INTERNAL = `${MAAT_ROOT_PATH}api/v1/reviewprivilege/queryReviewUserPrivilege`;

export const LANDING_URL_SEARCH_TASK_CREATE = `${MAAT_ROOT_PATH}api/v1/landing_page_check/submitQueryTask`;
export const LANDING_URL_SEARCH_TASK_LIST_FETCH = `${MAAT_ROOT_PATH}api/v1/landing_page_check/queryForTaskList`;
export const LANDING_URL_SEARCH_TASK_DETAIL_FETCH = `${MAAT_ROOT_PATH}api/v1/landing_page_check/queryForTaskInfo`;
export const LANDING_URL_SEARCH_TASK_DEAL = `${MAAT_ROOT_PATH}api/v1/landing_page_check/handleForTask`;
export const GET_INDUSTRY_INFO =  `${MAAT_ROOT_PATH}api/v1/review/getAllIndustryInfo`;

export const CREATIVE_FEEDS_DELETE_RECORD_FETCH = `${MAAT_ROOT_PATH}api/v1/proxy/queryFeedRecord`;
export const CREATIVE_FEEDS_DELETE = `${MAAT_ROOT_PATH}api/v1/proxy/deleteFeed`;
export const QUICK_APP_SNAPSHOT_BATCH_FETCH = `${MAAT_ROOT_PATH}api/v1/creativeReview/queryQuickAppSnapshot`;
export const DELAY_AUDIT_CREATIVE_REVIEW_CHANNEL_FETCH = `${MAAT_ROOT_PATH}api/v1/systemTool/getAllChannelVal`;
export const DELAY_AUDIT_CREATIVE_SEARCH = `${MAAT_ROOT_PATH}api/v1/creativeReview/searchForFeedTask`;
export const PUNISH_LABEL_MANAGE_MODULE = {
  PUNISH_LABEL_SEARCH: `${MAAT_ROOT_PATH}api/v1/patrol/labelingPunishment/search`,
  CREATE_PUNISH_LABEL: `${MAAT_ROOT_PATH}api/v1/patrol/labelingPunishment/insert`,
  DELETE_LABEL: `${MAAT_ROOT_PATH}api/v1/patrol/labelingPunishment/deleteLabel`,
  CLASSIFY_LABEL: `${MAAT_ROOT_PATH}api/v1/patrol/labelingPunishment/classify`,
};
export const DEST_PATROL_LABEL = `${MAAT_ROOT_PATH}api/v1/patrol/task/label`;
// 获取微信小程序截图
export const FETCH_WX_MINI_PROGRAM_SNAPSHOT = `${MAAT_ROOT_PATH}api/v1/creativeReview/queryMiniProgramSnapshot`;
export const FETCH_RAINBOW_CONFIG = `${MAAT_ROOT_PATH}api/v1/serverless/rainbow`;

export const OFFLINE_EVALUATE_TOOL = {
  FETCH_ASSET_TYPE: `${MAAT_ROOT_PATH}api/v1/maatToolApi/GetAssetType`,
  LOAD_REJECT_REASON: `${MAAT_ROOT_PATH}api/v1/maatToolApi/GetRejectReason`,
  EVALUATE_OVERVIEW: `${MAAT_ROOT_PATH}api/v1/maatToolApi/EffectEvaluation`,
  EVALUATE_DETAIL: `${MAAT_ROOT_PATH}api/v1/maatToolApi/EffectEvaluationDetail`,
  OFFLINE_CREATIVE: `${MAAT_ROOT_PATH}api/v1/maatToolApi/OfflineTask`,
};

export const VIDEO_KEYWORD_SEARCH = `${MAAT_ROOT_PATH}api/v1/maatToolApi/GetVideoOcrTid`;

