/**
 * Created by nolanwang on 18/4/27.
 * 错误信息提示工具类
 */
 import React from 'react';
 import { showErrorNotification } from '../components/common/MessageForAlert';
 import EmptyHrefLink from '../components/common/EmptyHrefLink';
 import copy from 'copy-to-clipboard';
 
 const ErrorMsgUtil = {
   creativeReivew: {
     parseErrorCode(code) {
       const reason = CREATIVE_AUDIT_ERROR_CODE[code] || code || '';
       return reason ? (`失败原因：${reason}`) : '';
     },
   },
   common: {
     showErrorPrompt(failDesc, time) {
       let msg;
       let traceId;
       let requestId;
       if (failDesc.indexOf('###') > -1) {
         [msg, traceId] = failDesc.split('###');
       } else if (failDesc.indexOf('#*#') > -1) {
         [msg, requestId] = failDesc.split('#*#');
       } else {
         msg = failDesc;
       }
 
       let desc;
       if (traceId || requestId) {
         desc = <React.Fragment>
             <p style={{ marginRight: '25px' }}>
                 <span style={{ marginRight: '10px' }}>{msg}</span>
                 <br/>
                 {traceId ? `traceId::${traceId}` : `requestId::${requestId}`}
             </p>
             <EmptyHrefLink style={{
               position: 'absolute',
               top: 0,
               bottom: 0,
               right: 20,
               display: 'flex',
               alignItems: 'center',
             }} onClick={() => {
               copy(traceId || requestId);
             }}>复制</EmptyHrefLink>
         </React.Fragment>;
       } else {
         desc = <React.Fragment>
             <p>
                 {msg}
             </p>
         </React.Fragment>;
       }
       showErrorNotification(desc, time);
     },
   },
 };
 
 
 // SUCCESS(0, "已审核"),
 //   ILLEGAL_PARAM(1, "参数不合法"),
 //   WX_AD(2, "微信广告，返回成功，但是实际未审核"),
 //   ORDER_AD(3, "Order合约广告，返回审核成功，但是实际未审核"),
 //   API_ERROR(4, "调用API时发生错误"),
 //
 //   EMPTY_CREATIVE(5,"空素材"),
 //   CREATIVE_DELETED(6,"素材删除状态"),
 //   CREATIVE_PAUSED_CANNOT_APPROVE(7,"素材暂停，不能通过"),
 //   REVIEW_FINGER_PRINT_ERROR(8,"审核指纹不同,素材已发生变化"),
 //   PREPARE_RESOURCE(9, "素材准备中"),
 //
 //   ADGROUP_NULL(10, "广告信息为空"),
 //   ADGROUP_DELETED(11,"广告状态删除"),
 //   ADGROUP_WITH_PENDING_AD(12, "广告有待审核的素材"),
 //   ADGROUP_ALREADY_APPROVED(13, "广告已经是通过状态"),
 //   AD_CHANGED(14, "素材有变化"),
 //   ADGROUP_UPDATE_DB_ERROR(15, "审核广告后，更新db状态失败"),
 const CREATIVE_AUDIT_ERROR_CODE = {
   0: '已审核',
   1: '参数不合法',
   2: '微信广告，返回成功，但是实际未审核',
   3: 'Order合约广告，返回审核成功，但是实际未审核',
   4: '调用API时发生错误',
   5: '空素材',
   6: '素材删除状态',
   7: '素材暂停，不能通过',
   8: '审核指纹不同,素材已发生变化',
   9: '素材准备中',
   10: '广告信息为空',
   11: '广告状态删除',
   12: '广告有待审核的素材',
   13: '广告已经是通过状态',
   14: '素材有变化',
   15: '审核广告后，更新db状态失败',
 
   20: '获取通道信息失败',
   21: '该通道已被审核通过，不需重复操作',
   22: '已有XQ,XS审核结果，不需进行联盟通道审核',
   23: '不需要审核处理',
   30: '目前该广告正在审核中，请稍后重试',
   40: '通道审核结果未覆盖全部通道',
   41: '依据素材计算广告的审核状态失败',
 };
 
 
 export default ErrorMsgUtil;
 
 