import {
  TABLE_RENDER,
  MEDIA_TIME,
  ADVERTISER_REMARK_STAY_TIME,
} from "../consts/BeaconEventCode";
import { get } from "lodash";

const report = (eventCode, params, reportApi) => {
  if (
    get(window, "pageProps.userRole") === 1 ||
    get(window, "pageProps.userRole") === 2
  ) {
    return;
  }

  const exec = (eventCode, params, reportApi) => {
    let finalParam = {
      ...params,
    };
    if (!finalParam.event_client_time) {
      finalParam = { ...finalParam, event_client_time: Date.now() };
    }
    try {
      window.beaconReport[reportApi](eventCode, finalParam);
    } catch (e) {}
  };

  if (window.beaconReport && window.beaconReport[reportApi]) {
    exec(eventCode, params, reportApi);

    // cache
    let cache = window.localStorage.getItem("report-cache");
    try {
      cache = JSON.parse(cache);
      if (Array.isArray(cache)) {
        cache.forEach(({ eventCode, params, reportApi }) => {
          exec(eventCode, params, reportApi);
          window.localStorage.setItem("report-cache", "[]");
        });
      }
    } catch (e) {}
  } else {
    try {
      window.localStorage.removeItem("report-cache");
    } catch (e) {
      console.log(e);
    }
    // let cache = window.localStorage.getItem('report-cache');
    // try {
    //   cache = JSON.parse(cache);
    //   if (!Array.isArray(cache)) {
    //     cache = [];
    //   }
    // } catch (e) {
    //   cache = [];
    // }
    // cache.push({ eventCode, params, reportApi });
    // window.localStorage.setItem('report-cache', JSON.stringify(cache));
  }
};

const beaconUtil = {
  common: {
    addEventCommonParams(params) {
      if (window.beaconReport) {
        window.beaconReport.addAdditionalParams(params);
        if (params.reviewer) {
          this.setUserId(params.reviewer);
        }
      }
    },
    setUserId(userId) {
      if (window.beaconReport) {
        window.beaconReport.setOpenId(userId);
      }
    },
    /* 普通上报事件*/
    userAction(eventCode, params) {
      report(eventCode, params, "onUserAction");
    },
    /* 实时上报事件*/
    directUserAction(eventCode, params) {
      report(eventCode, params, "onDirectUserAction");
    },
    /* 针对某些在页面跳转、关闭时生成的事件，避免因为进程关闭太快导致上报失败*/
    sendBeaconOnRedirect(eventCode, params) {
      report(eventCode, params, "onSendBeacon");
    },
  },
  creativeTakeTask: {
    commonParams: {},
    addEventCommonParams(params = {}) {
      this.commonParams = { ...(this.commonParams || {}), ...params };
    },
    report(eventCode, params, reportApi) {
      report(eventCode, { ...this.commonParams, ...params }, reportApi);
    },
    userAction(eventCode, params) {
      this.report(eventCode, params, "onUserAction");
    },
    directUserAction(eventCode, params) {
      this.report(eventCode, params, "onDirectUserAction");
    },
    sendBeaconOnRedirect(eventCode, params) {
      this.report(eventCode, params, "onSendBeacon");
    },
  },
  tableRender: (startTime, tableTitle) => {
    const endTime = new Date();
    const param = { time: endTime - startTime, table_title: tableTitle };
    beaconUtil.common.userAction(TABLE_RENDER, param);
  },
  mediaTime: (startTime, number, id) => {
    const endTime = new Date();
    const location = window.location.hash.split(/[!*'();:@&=+$,?[\]]/, 1)[0];
    const pagePath = location.split("#", 2)[1];
    const param = {
      time: endTime - startTime,
      page_path: pagePath,
      media_number: number,
      id,
    };
    if (number > 0) {
      beaconUtil.common.userAction(MEDIA_TIME, param);
    }
  },
  advertiserRemark: (startTime, param) => {
    const endTime = new Date();
    const pagePath = window.location.hash
      .split(/[!*'();:@&=+$,?[\]]/, 1)[0]
      .split("#", 2)[1];
    const params = {
      stay_time: endTime - startTime,
      page_path: pagePath,
      ...param,
    };
    beaconUtil.common.userAction(ADVERTISER_REMARK_STAY_TIME, params);
  },
};

export default beaconUtil;
