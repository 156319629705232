/**
 * Created by nolanwang on 2018/07/31.
 * 基于fetch 封装的网络请求工具类
 */
import { Component } from "react";
import { ErrorCode } from "../consts/ErrorCode";
import { ERR_CODE } from "../containers/login/constants";
import { logPerformance } from "./performanceLog";
import {
  AlertTransportUrls,
  RecordTransportUrls,
  TransportResponseCodeCheck,
} from "../consts/TransportRequestUrl";
import aegisRecordHelpler from "../helplers/aegisRecordHelpler";
import { showErrorNotification } from "../components/common/MessageForAlert";
import beaconUtil from "./beaconReportUtil";
import url from "url";
import ErrorMsgUtil from "./ErrorMsgUtil";

/**
 * fetch 网络请求的header，可自定义header 内容
 * @type {{Accept: string, Content-Type: string, accessToken: *}}
 */
const header = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

/**
 * GET 请求时，拼接请求URL
 * @param url 请求URL
 * @param params 请求参数
 * @returns {*}
 */
const handleUrl = (url) => (params) => {
  if (params) {
    const paramsArray = [];
    Object.keys(params).forEach((key) =>
      paramsArray.push(`${key}=${encodeURIComponent(params[key])}`)
    );
    if (url.search(/\?/) === -1) {
      // eslint-disable-next-line no-unused-expressions
      typeof params === "object" ? (url += `?${paramsArray.join("&")}`) : url;
    } else {
      url += `&${paramsArray.join("&")}`;
    }
  }

  const newUlr = addCommonParam(url);
  return newUlr;
};

const addCommonParam = (url) => {
  let newUrl = url;
  const aElem = document.createElement("a");
  aElem.href = url;
  const { search } = aElem;
  if (search) {
    if (
      search.indexOf("?") < search.length - 1 &&
      search.indexOf("&") < search.length - 1
    ) {
      newUrl += "&";
    }
  } else {
    newUrl += "?";
  }

  newUrl += `reqId=${Math.random()}`;

  return newUrl;
};

const transportAlert = (url) => {
  const transport =
    AlertTransportUrls.findIndex((uri) => url.indexOf(uri) > -1) > -1;
  return transport;
};

const transportRecord = (url) => {
  const transport =
    RecordTransportUrls.findIndex((uri) => url.indexOf(uri) > -1) > -1;
  return transport;
};

const transportCodeCheck = (url) =>
  TransportResponseCodeCheck.findIndex((uri) => url.indexOf(uri) > -1) > -1;

const responseOkCallback = ({
  url,
  response,
  finalUrl,
  params,
  responseHandler,
}) => {
  if (!transportCodeCheck(url)) {
    if (response && response.code === ErrorCode.NO_LOGIN_OR_NO_DATA_RIGHT) {
      showErrorNotification(
        `未登录或没有权限查看相关内容,请访问:${response.msg}进行登陆`
      );
    } else {
      if (response && response.code !== 0) {
        aegisRecordHelpler.ajaxExceptionReport({
          url,
          response,
          subType: "codenotzero",
        });
      }
    }
  }

  try {
    if (!transportRecord(url)) {
      logPerformance(finalUrl);
    }
  } catch (e) {
    console.error(e);
  }

  try {
    if (response && response.code !== undefined) {
      window.pageProps.code = response.code;
    }
  } catch (e) {
    console.error(e);
  }

  if (typeof responseHandler === "function") {
    let finalResponse = response;
    if (response && response.traceId) {
      finalResponse = {
        ...response,
        msg: `${response.msg}###${response.traceId}`,
      };
    }
    try {
      return responseHandler(finalResponse, params);
    } catch (e) {
      console.error(e);
    }
  }

  return response;
};

const responseNot200Callback = (response) => {
  if (response && (response.status === 401 || response.status === 403)) {
    window.pageProps.code =
      response.status === 401
        ? ERR_CODE.ERRCODE_NEED_LOGIN
        : ERR_CODE.ERRCODE_USER_NO_RIGHT;
    //  showErrorNotification(`${response.status} ${response.statusText} 登录失效，请刷新页面重新登录`);
  } else {
    throw new Error(
      `response::${response} 返回码::` +
        `${response && response.status},${response && response.statusText}`
    );
  }
};

const fetchExceptionCallback = ({
  url,
  finalUrl,
  error,
  params,
  responseHandler,
}) => {
  aegisRecordHelpler.ajaxExceptionReport({
    url: finalUrl,
    error,
  });

  //  if (!transportAlert(url)) {
  //    const link = document.createElement('a');
  //    link.href = finalUrl;
  //    const paramInstance = new URLSearchParams(link.search);
  //    const requestId = paramInstance.get('reqId');
  //    ErrorMsgUtil.common.showErrorPrompt(`如下请求失败：${finalUrl}  ${error
  //    }  请重新操作，并将该信息反馈#*#${requestId}`);
  //  }
  try {
    if (!transportRecord(url)) {
      logPerformance(finalUrl);
    }
  } catch (e) {
    console.error(e);
  }

  let response;
  if (typeof responseHandler === "function") {
    response = {
      fake: true,
    };
    response = responseHandler(response, params);
  }

  return response;
};

/**
 * fetch 网络请求超时处理
 * @param original_promise 原始的fetch
 * @param timeout 超时时间 100s
 * @returns {Promise.<*>}
 */
const timeoutFetch = (originalFetch, timeout = 100000) => {
  // Promise.race(iterable)方法返回一个promise
  // 这个promise在iterable中的任意一个promise被解决或拒绝后，立刻以相同的解决值被解决或以相同的拒绝原因被拒绝。
  const beginTime = Date.now();
  return Promise.race([originalFetch]).then((res) => {
    beaconUtil.common.userAction("frontend_api_request_time", {
      api_url: res.url,
      api_path: url.parse(res.url).pathname,
      time: Date.now() - beginTime,
    });
    return res;
  });
};

export default class HttpUtils extends Component {
  /**
   * 基于fetch 封装的GET 网络请求
   * @param url 请求URL
   * @param params 请求参数
   * @returns {Promise}
   */
  static getRequest = (url, params = {}, responseHandler) => {
    const finalUrl = handleUrl(url)(params);
    return timeoutFetch(
      fetch(finalUrl, {
        method: "GET",
        headers: header,
        credentials: "include",
      })
    )
      .then((response) => {
        if (response && response.ok) {
          return response.json();
        }
        responseNot200Callback(response);
      })
      .then((response) =>
        responseOkCallback({ url, response, finalUrl, params, responseHandler })
      )
      .catch((error) =>
        fetchExceptionCallback({
          url,
          finalUrl,
          error,
          responseHandler,
          params,
        })
      );
  };

  /**
   * 基于fetch 的 POST 请求
   * @param url 请求的URL
   * @param params 请求参数
   * @returns {Promise}
   */
  static postRequrst = (url, params = {}, responseHandler, noSession) => {
    const finalParam = noSession
      ? JSON.stringify(params)
      : JSON.stringify({
          ...params,
          session: window.pageProps.session,
        });
    const finalUrl = addCommonParam(url);
    return timeoutFetch(
      fetch(finalUrl, {
        method: "POST",
        headers: header,
        body: finalParam,
        credentials: "include",
      })
    )
      .then((response) => {
        if (response && response.ok) {
          return response.json();
        }
        responseNot200Callback(response);
      })
      .then((response) =>
        responseOkCallback({
          response,
          url,
          finalUrl,
          params,
          responseHandler,
        })
      )
      .catch((error) =>
        fetchExceptionCallback({
          url,
          finalUrl,
          responseHandler,
          error,
          params,
        })
      );
  };
  static putRequest = (url, params, callback, contentType) => {
    let promise = null;
    let finalUrl;
    if (contentType === "json") {
      finalUrl = addCommonParam(url);
      promise = timeoutFetch(
        fetch(finalUrl, {
          method: "PUT",
          headers: header,
          body: JSON.stringify(params),
          contentType: "application/json",
          credentials: "include",
        })
      );
    } else {
      finalUrl = handleUrl(url)(params);
      promise = timeoutFetch(
        fetch(finalUrl, {
          method: "PUT",
          headers: header,
          credentials: "include",
        })
      );
    }
    return promise
      .then((response) => {
        if (response && response.ok) {
          return response.json();
        }
        responseNot200Callback(response);
      })
      .then((response) =>
        responseOkCallback({
          url,
          response,
          finalUrl,
          params,
          responseHandler: callback,
        })
      )
      .catch((error) =>
        fetchExceptionCallback({
          url,
          finalUrl,
          error,
          responseHandler: callback,
        })
      );
  };

  static deleteRequest = (url, params) => {
    const finalUrl = handleUrl(url)(params);
    return timeoutFetch(
      fetch(finalUrl, {
        method: "DELETE",
        headers: header,
        credentials: "include",
      })
    )
      .then((response) => {
        if (response && response.ok) {
          return response.json();
        }
        responseNot200Callback(response);
      })
      .then((response) =>
        responseOkCallback({ url, response, finalUrl, params })
      )
      .catch((error) =>
        fetchExceptionCallback({ url, params, error, finalUrl })
      );
  };
}
