import React from 'react';

const EmptyHrefLink = React.forwardRef((props, ref) => {
  const { children, ...linkProps } = props;
  return (
  <a ref={(node) => {
    if (ref) {
      if (typeof ref === 'function') {
        ref(node);
      } else {
        ref.current = node;
      }
    }
    node && node.setAttribute('href', 'javascript:;');
  }}
     {...linkProps}
  >
    {children}
  </a>
  );
});

export default EmptyHrefLink;