import "./css/review_layout.css";
import "./css/review.css";
import "./css/review_customization.css";
import "./css/review_rule.css";
import "./css/review_rule_custom.css";
import { isWxLogin } from "./js/utils/LoginUtils";
import { ERR_CODE } from "./js/containers/login/constants";

window.pageProps = {
  permissionIds: "",
  reviewerName: "",
  reviewerId: 0,
  session: {},
  code: ERR_CODE.ERRCODE_NEED_LOGIN,
  env: "",
};

// if ((window.location.protocol === 'https:' || window.location.protocol === 'http:') &&
// window.location.host === 'xuanjing.qq.com' && !window.location.pathname.includes("/maat/")) {
//   window.location.href = window.location.protocol + '//xuanjing.qq.com/maat/';
// }
// if (window.location.protocol === 'https:' && window.location.host === 't.xuanjing.qq.com' && !window.location.pathname.includes("/maat/")) {
//   window.location.href = 'https://t.xuanjing.qq.com/maat/';
// }

isWxLogin(() => {
  import("./index");
});
