import * as Url from './Urls';

export const AlertTransportUrls = [
  Url.default.WXLOGIN,
  Url.default.USERINFO,
  Url.default.GET_CREATIVE_LOGIN_STATUS,
  Url.default.FETCH_CHANNEL_WARN_INFO,
  Url.default.CREATIVE_AUDIT_TIMING_START,
  Url.default.CREATIVE_AUDIT_SET_TIME_OUT,
  Url.default.CREATIVE_AUDIT_GET_TIME_OUT_COUNT,
  Url.default.CLICK_MO_REPORT,
];

export const RecordTransportUrls = [
  Url.default.GET_CREATIVE_LOGIN_STATUS,
  Url.default.CLICK_MO_REPORT,
];

export const TransportResponseCodeCheck = [
  Url.default.GET_ALL_CHANNELS,
];