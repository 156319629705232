import message from '@tencent/spaui-message';

export function configGlobalNotificationMessage() {
  message.config({
    instanceId: 'globalNotification',
    duration: 3, // 默认关闭延迟时间
    maxCount: 3, // 最大显示数, 超过限制时，最早的消息会被自动关闭
    top: 100, // 消息距离顶部的位置
    position: 'center',
    portalClassName: 'custom-message-portal',
  });
}

export function showErrorNotification(desc, time) {
  message.error({
    message: desc,
    closable: true,
    duration: time,
    instanceId: 'globalNotification',
    style: {
      width: 500,
    },
  });
}
