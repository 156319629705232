let index = 0;

export function logPerformance(url) {
  if (window.performance && window.performance.getEntries) {
    setTimeout(() => {
      const allEntryList = [].concat(window.performance.getEntries());
      const needSearchSubArr = allEntryList.slice(index);
      index = allEntryList.length;
      const entryInfo = needSearchSubArr.find((entryItem) => {
        const { name } = entryItem;
        if (name.indexOf(url) > -1) {
          return true;
        }
      });

      if (entryInfo) {
        const entryJsonInfo = entryInfo.toJSON();
        entryJsonInfo.url = url;
        window.bugout && window.bugout.log(entryJsonInfo);
      }
    }, 1000);
  }
}
